import { onValue, ref, update } from 'firebase/database';
import React, { useState } from 'react';
import { database } from '../config';

const Admin = () => {
    const stateRef = ref(database, '/');
    const [isShow, setIsShow] = useState(false);

    onValue(stateRef, (snapshot) => {
        const data = snapshot.val();
        console.log(data);
        if (data.isShowPopUp !== isShow) setIsShow(data.isShowPopUp);
    });

    const handleChangeState = () => {
        update(stateRef, { isShowPopUp: !isShow });
    };

    return (
        <div>
            <img
                src={process.env.PUBLIC_URL + '/assets/head.webp'}
                alt="header"
                className="w-full animate__animated animate__fadeIn animate__slow"
            />
            <div className="h-64 w-full flex justify-center items-center">
                <button className="button-name" onClick={handleChangeState}>
                    {isShow ? 'Ẩn' : 'Hiển thị'} Pop Up
                </button>
            </div>
        </div>
    );
};

export default Admin;
