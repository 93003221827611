// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
// import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyBrShUZvpraGLPjEaekK9PQRTln7kHt480',
    authDomain: 'ajc-secret-gift.firebaseapp.com',
    databaseURL:
        'https://ajc-secret-gift-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'ajc-secret-gift',
    storageBucket: 'ajc-secret-gift.appspot.com',
    messagingSenderId: '318449494518',
    appId: '1:318449494518:web:b1c3c33d067dc8a2c7bfd7',
    measurementId: 'G-NVSQNK7YG1',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
// export const db = getFirestore(app);
// const analytics = getAnalytics(app);
